import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { isDebugMode } from '@/config';
import api from '@/utils/api';
import { useToast } from '@/composables/useToast';

export interface ServiceOption {
  id?: number;
  duration: number;
  fee: string;
  service_id?: number;
}

export interface Service {
  id: number;
  name: string;
  description: string;
  category: string;
  user_id: number;
  email_template_id: number | null;
  intake_form_id: number | null;
  require_intake_form: boolean;
  requires_account: boolean;
  requires_credit_card: boolean;
  options: ServiceOption[];
  intake_form?: any;
  created_at?: string;
  updated_at?: string;
  fee?: string;
  working_days?: number[];
  username?: string;
}

export interface ServiceTemplate {
  id: number;
  template_id?: string;
  version?: string;
  name: string;
  description: string;
  category: string;
  icon: string;
  options: ServiceOption[];
  email_template?: string;
  intake_form?: string;
  accessibility?: any;
  is_system?: boolean;
  created_by?: number;
  created_at?: string;
  updated_at?: string;
  usage_count?: number;
  last_used?: string;
}

export interface ServiceCreate {
  name: string;
  description: string;
  options: {
    duration: number;
    fee: string;
  }[];
  category: string;
  email_template_id: number | null;
  intake_form_id: number | null;
  requires_account?: boolean;
  requires_credit_card?: boolean;
  require_intake_form?: boolean;
}

export interface ServiceUpdate extends ServiceCreate {
  id: number;
}

export const useServicesStore = defineStore('services', () => {
  const { showToast } = useToast();

  // State
  const services = ref<Service[]>([]);
  const serviceTemplates = ref<ServiceTemplate[]>([]);
  const isLoading = ref<boolean>(false);
  const selectedService = ref<Service | null>(null);
  const error = ref<string | null>(null);

  // Getters
  const getServiceById = computed(() => (id: number) => {
    return services.value.find(service => service.id === id);
  });

  // Service Template Actions
  const fetchServiceTemplates = async () => {
    try {
      isLoading.value = true;
      const response = await api.get('/templates/');
      if (response.data) {
        serviceTemplates.value = response.data;
      }
      error.value = null;
    } catch (err) {
      if (isDebugMode()) {
        console.error('Error fetching service templates:', err);
      }
      error.value = 'Failed to load service templates. Please try again later.';
      showToast('Failed to load service templates. Please try again later.', 'error');
      serviceTemplates.value = [];
    } finally {
      isLoading.value = false;
    }
  };

  // Actions
  const fetchServices = async () => {
    try {
      isLoading.value = true;
      const response = await api.get('/services/');
      if (response.data) {
        services.value = response.data.map((service: Service) => ({
          ...service,
          fee: service.fee ? parseFloat(service.fee).toFixed(2) : '0.00',
          options: service.options?.map(option => ({
            ...option,
            fee: option.fee ? parseFloat(option.fee).toFixed(2) : '0.00'
          })) || []
        }));
      }
      error.value = null;
    } catch (err) {
      if (isDebugMode()) {
        console.error('Error fetching services:', err);
      }
      error.value = 'Failed to load services. Please try again later.';
      showToast('Failed to load services. Please try again later.', 'error');
      services.value = [];
    } finally {
      isLoading.value = false;
    }
  };

  const createService = async (serviceData: ServiceCreate, signal?: AbortSignal) => {
    try {
      if (isDebugMode()) {
        console.log('Creating service with data:', serviceData);
      }

      // Ensure options are properly formatted
      const formattedData = {
        ...serviceData,
        options: serviceData.options.map(opt => ({
          duration: Number(opt.duration),
          fee: String(opt.fee)
        }))
      };

      const response = await api.post('/services/', formattedData, { signal });
      if (response.data) {
        if (isDebugMode()) {
          console.log('Service created successfully:', response.data);
        }
        await fetchServices();
        showToast('Service created successfully', 'success');
        return response.data;
      }
    } catch (err) {
      if (err instanceof Error && err.name === 'AbortError') {
        if (isDebugMode()) {
          console.log('Service creation cancelled');
        }
        throw err;
      }
      if (isDebugMode()) {
        console.error('Error creating service:', err);
      }
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      showToast(`Error creating service: ${errorMessage}`, 'error');
      throw err;
    }
  };

  const updateService = async (serviceData: ServiceUpdate) => {
    try {
      if (!serviceData.id) {
        throw new Error('Service ID is missing');
      }

      const serviceId = parseInt(serviceData.id.toString(), 10);
      if (isNaN(serviceId)) {
        throw new Error('Invalid service ID');
      }

      if (isDebugMode()) {
        console.log('Updating service with data:', serviceData);
      }

      const response = await api.put(`/services/${serviceId}`, serviceData);
      await fetchServices();
      showToast('Service updated successfully', 'success');
      return response.data;
    } catch (err) {
      if (isDebugMode()) {
        console.error('Error updating service:', err);
      }
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      showToast(`Error updating service: ${errorMessage}`, 'error');
      throw err;
    }
  };

  const deleteService = async (serviceId: number, signal?: AbortSignal) => {
    try {
      if (!serviceId) {
        throw new Error('No service selected for deletion');
      }

      await api.delete(`/services/${serviceId}`, { signal });
      services.value = services.value.filter(s => s.id !== serviceId);
      showToast('Service deleted successfully', 'success', {
        customClass: 'bg-red-600 text-white shadow-lg'
      });
    } catch (err) {
      if (err instanceof Error && err.name === 'AbortError') {
        if (isDebugMode()) {
          console.log('Service deletion cancelled');
        }
        throw err;
      }
      if (isDebugMode()) {
        console.error('Error deleting service:', err);
      }
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      showToast(`Error deleting service: ${errorMessage}`, 'error');
      throw err;
    }
  };

  const selectService = (service: Service | null) => {
    selectedService.value = service;
  };

  const fetchPublicServices = async (username: string) => {
    if (!username) {
      error.value = 'Username is required to fetch public services';
      showToast('Username is required to fetch public services', 'error');
      return [];
    }

    try {
      isLoading.value = true;
      if (isDebugMode()) {
        console.log('Fetching public services for username:', username);
      }
      const response = await api.get(`/users/public/${username}`);
      if (response.data) {
        services.value = response.data.services.map((service: Service) => ({
          ...service,
          fee: service.fee ? parseFloat(service.fee).toFixed(2) : '0.00',
          options: service.options?.map(option => ({
            ...option,
            fee: option.fee ? parseFloat(option.fee).toFixed(2) : '0.00'
          })) || []
        }));
        return services.value;
      }
      return [];
    } catch (err) {
      if (isDebugMode()) {
        console.error('Error fetching public services:', err);
      }
      error.value = 'Failed to load services. Please try again later.';
      showToast('Failed to load services. Please try again later.', 'error');
      return [];
    } finally {
      isLoading.value = false;
    }
  };

  return {
    // State
    services,
    serviceTemplates,
    isLoading,
    selectedService,
    error,

    // Getters
    getServiceById,

    // Actions
    fetchServices,
    fetchServiceTemplates,
    createService,
    updateService,
    deleteService,
    selectService,
    fetchPublicServices
  };
}); 