<template>
  <component
    :is="computedTag"
    :class="[
      'rounded-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 inline-flex items-center justify-center',
      variantClasses[variant],
      sizeClasses[size],
      { 'opacity-50 cursor-not-allowed': disabled },
      className
    ]"
    :disabled="disabled"
    :aria-label="$attrs['aria-label']"
    v-bind="$attrs"
    ref="buttonRef"
    :to="to"
    :role="computedTag !== 'button' ? 'button' : undefined"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { RouterLink } from 'vue-router';

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outline' | 'ghost' | 'destructive'
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
  className?: string
  tag?: string | object
  to?: string
}

const props = withDefaults(defineProps<ButtonProps>(), {
  variant: 'primary',
  size: 'md',
  disabled: false,
  className: '',
  tag: 'button',
  to: undefined
})

const buttonRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  focus: () => buttonRef.value?.focus()
});

const computedTag = computed(() => {
  // If we have a 'to' prop and it's not explicitly overridden by tag prop
  if (props.to && props.tag === 'button') {
    return RouterLink;
  }
  return props.tag;
});

const variantClasses = {
  primary: 'bg-breathe-dark-tertiary text-white hover:opacity-90',
  secondary: 'bg-white border-2 border-breathe-dark-tertiary text-breathe-dark-tertiary hover:bg-breathe-dark-tertiary hover:text-white',
  tertiary: 'bg-gray-100 text-gray-700 hover:bg-gray-200',
  outline: 'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50',
  ghost: 'bg-transparent text-gray-700 hover:bg-gray-100',
  destructive: 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500'
}

const sizeClasses = {
  sm: 'px-2 py-0.5 text-xs gap-2',
  md: 'px-3 py-1 text-sm gap-2',
  lg: 'px-4 py-2 text-base gap-3'
}
</script> 