import { defineStore } from 'pinia'
import { ref } from 'vue'
import { isDebugMode } from '@/config'
import api from '@/utils/api'
import { useToast } from '@/composables/useToast'
import { useAuthStore } from '@/stores/auth'

export interface FormField {
  name: string
  label: string
  field_type: string
  options?: string[]
  is_required: boolean
  order: number
  [key: string]: any
}

export interface Form {
  id: string
  name: string
  description?: string
  fields: FormField[]
  is_active: boolean
  terms_and_conditions?: string
  [key: string]: any
}

export interface FormCreate {
  name: string
  description?: string
  fields: FormField[]
  is_active: boolean
  terms_and_conditions?: string
}

export const useFormsStore = defineStore('forms', () => {
  const forms = ref<Form[]>([])
  const isLoading = ref(false)
  const error = ref<string | null>(null)
  const { showToast } = useToast()
  const authStore = useAuthStore()

  const fetchForms = async () => {
    isLoading.value = true
    try {
      if (!authStore.currentUser?.id) {
        throw new Error('User not logged in or missing id')
      }

      const response = await api.get('/intake-forms/')
      const fetchedForms = response.data
      
      // Process the forms to ensure fields are properly structured
      forms.value = fetchedForms.map((form: Form) => ({
        ...form,
        fields: form.fields ? (
          Array.isArray(form.fields) 
            ? form.fields 
            : Object.values(form.fields) as FormField[]
        ).map((field: FormField) => ({
          ...field,
          label: field.label || field.name || '',
          name: field.name || (field.label?.toLowerCase().replace(/\s+/g, '_')) || ''
        })) : []
      }))

      if (isDebugMode()) {
        console.log('[Forms Store] Forms loaded:', forms.value)
      }
    } catch (err) {
      if (isDebugMode()) {
        console.error('[Forms Store] Error loading forms:', err)
      }
      error.value = err instanceof Error ? err.message : 'Failed to fetch forms'
      showToast('Failed to fetch forms', 'error')
      throw err
    } finally {
      isLoading.value = false
    }
  }

  const createForm = async (formData: FormCreate) => {
    isLoading.value = true
    try {
      if (!authStore.currentUser?.id) {
        throw new Error('User not logged in or missing id')
      }

      // Convert options array to object or null
      const processedFormData = {
        ...formData,
        fields: formData.fields.map(field => ({
          ...field,
          options: field.options && field.options.length > 0
            ? Object.fromEntries(field.options.map((opt, index) => [index.toString(), opt]))
            : null
        }))
      }

      const response = await api.post('/intake-forms/', processedFormData)
      const savedForm = response.data

      forms.value.push(savedForm)
      showToast('Form created successfully', 'success')

      if (isDebugMode()) {
        console.log('[Forms Store] Form created:', savedForm)
      }

      return savedForm
    } catch (err) {
      if (isDebugMode()) {
        console.error('[Forms Store] Error creating form:', err)
      }
      error.value = err instanceof Error ? err.message : 'Failed to create form'
      showToast('Failed to create form', 'error')
      throw err
    } finally {
      isLoading.value = false
    }
  }

  const updateForm = async (formId: string, formData: Partial<FormCreate>) => {
    isLoading.value = true
    try {
      if (!authStore.currentUser?.id) {
        throw new Error('User not logged in or missing id')
      }

      // Use the same processing as createForm
      const processedFormData = {
        ...formData,
        fields: formData.fields?.map(field => ({
          ...field,
          options: field.options && field.options.length > 0
            ? Object.fromEntries(field.options.map((opt, index) => [index.toString(), opt]))
            : null
        })) || []
      }

      if (isDebugMode()) {
        console.log('[Forms Store] Updating form with data:', processedFormData);
      }

      const response = await api.put(`/intake-forms/${formId}`, processedFormData)
      const updatedForm = response.data

      const index = forms.value.findIndex(f => f.id === formId)
      if (index !== -1) {
        forms.value[index] = updatedForm
      }

      showToast('Form updated successfully', 'success')

      if (isDebugMode()) {
        console.log('[Forms Store] Form updated:', updatedForm)
      }

      return updatedForm
    } catch (err) {
      if (isDebugMode()) {
        console.error('[Forms Store] Error updating form:', err)
      }
      error.value = err instanceof Error ? err.message : 'Failed to update form'
      showToast('Failed to update form', 'error')
      throw err
    } finally {
      isLoading.value = false
    }
  }

  const deleteForm = async (formId: string) => {
    isLoading.value = true
    try {
      if (!authStore.currentUser?.id) {
        throw new Error('User not logged in or missing id')
      }

      await api.delete(`/intake-forms/${formId}`)
      forms.value = forms.value.filter(form => form.id !== formId)

      if (isDebugMode()) {
        console.log('[Forms Store] Form deleted:', formId)
      }
    } catch (err) {
      if (isDebugMode()) {
        console.error('[Forms Store] Error deleting form:', err)
      }
      error.value = err instanceof Error ? err.message : 'Failed to delete form'
      throw err
    } finally {
      isLoading.value = false
    }
  }

  return {
    forms,
    isLoading,
    error,
    fetchForms,
    createForm,
    updateForm,
    deleteForm
  }
}) 