import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
import { logger } from '@/utils/logger'

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'http://localhost:8000',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Add request interceptor to add auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add response interceptor to handle token refresh
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    const authStore = useAuthStore()

    // If error is network-related, don't attempt refresh
    if (!error.response) {
      return Promise.reject(error)
    }

    // Only attempt refresh if:
    // 1. Error is 401 Unauthorized
    // 2. Request is not already retrying
    // 3. Request is not to /auth/refresh
    // 4. Request is not to /auth/token
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url?.includes('/auth/refresh') &&
      !originalRequest.url?.includes('/auth/token')
    ) {
      originalRequest._retry = true

      try {
        // Attempt to refresh the token
        const newToken = await authStore.refreshToken()
        if (newToken) {
          // Update the failed request's auth header
          originalRequest.headers.Authorization = `Bearer ${newToken}`
          // Retry the failed request
          return api(originalRequest)
        }
      } catch (refreshError) {
        logger.error('Token refresh failed in API interceptor', {
          module: 'API',
          metadata: { error: refreshError }
        })
        // If refresh fails, reject with the original error
        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }
)

// Export both as default and named export
export default api