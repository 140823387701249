<template>
  <footer 
    class="bg-breathe-dark-secondary text-white shadow-md fixed bottom-0 left-0 right-0 z-50" 
    role="contentinfo" 
    aria-label="Footer" 
    data-testid="footer-container"
  >
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8" data-testid="footer-content">
      <div class="flex items-center justify-between py-0.5 rounded-md text-sm">
        <div class="flex items-center relative">
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="!showFeedback || !isLoggedIn"
              :key="'default'"
              class="flex flex-row items-center gap-2"
              data-testid="footer-links"
            >
              <router-link
                v-if="isLoggedIn"
                to="/about"
                class="text-white hover:text-breathe-light-primary px-2 py-1 font-medium min-h-[44px] min-w-[44px] flex items-center justify-center transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-breathe-light-primary rounded-md"
                aria-label="About Just Breathe"
              >
                About
              </router-link>
              <router-link
                to="/privacy"
                class="text-white hover:text-breathe-light-primary px-2 py-1 font-medium min-h-[44px] min-w-[44px] flex items-center justify-center transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-breathe-light-primary rounded-md"
                aria-label="View Privacy Policy"
              >
                {{ isLoggedIn ? 'Privacy' : 'Privacy Policy' }}
              </router-link>
            </div>
            <div
              v-else
              :key="'feedback'"
              data-testid="feedback-link"
            >
              <a
                href="https://signal.group/#CjQKIMWDjGZvLYtcNr71f3KmEAnm2jgYhJkDKELj80G3SRGHEhC-vnv3XIss6smSZY5z9_PY"
                class="text-white bg-breathe-dark-tertiary hover:bg-opacity-90 px-2 py-1 rounded-lg font-medium min-h-[30px] min-w-[44px] flex items-center justify-center transition-colors duration-200 animate-pulse focus:outline-none focus:ring-2 focus:ring-breathe-light-primary"
                aria-label="Join our Signal group for feedback (opens in new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                Give Feedback
              </a>
            </div>
          </transition>
        </div>
        <div 
          class="flex items-center text-white text-sm md:text-base px-2 min-h-[44px]" 
          data-testid="footer-version"
          aria-label="Version and copyright information"
        >
          <span
            v-if="apiVersion"
            class="mr-1 text-breathe-light-primary"
            aria-label="Application version"
          >{{ apiVersion }} alpha</span>
          &nbsp;©
          <a 
            href="https://mindfulenterprise.com" 
            class="text-white ml-1 hover:text-breathe-light-primary transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-breathe-light-primary rounded-md min-h-[44px] min-w-[44px] flex items-center justify-center"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Mindful Enterprise website (opens in new tab)"
          >
            {{ currentYear }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { API_URL } from '@/config';
import axios from 'axios';
import { logger } from '@/utils/logger';

const authStore = useAuthStore();
const logOptions = { module: 'FooterBar' };
const isLoggedIn = computed(() => authStore.isAuthenticated);
const showFeedback = ref(false);
const apiVersion = ref<string | null>(null);
const currentYear = new Date().getFullYear();
let intervalId: ReturnType<typeof setInterval> | null = null;

const fetchApiVersion = async () => {
  logger.debug('Fetching API version', { 
    ...logOptions, 
    metadata: { url: `${API_URL}/system/version` }
  });
  try {
    const response = await axios.get(`${API_URL}/system/version`);
    logger.debug('API Version Response received', { 
      ...logOptions, 
      metadata: { response: response.data }
    });
    if (response.data && response.data.version) {
      apiVersion.value = response.data.version;
      logger.debug('API Version set', { 
        ...logOptions, 
        metadata: { version: apiVersion.value }
      });
    } else {
      throw new Error('Invalid version response');
    }
  } catch (error) {
    apiVersion.value = 'v1';
    logger.error('Error fetching API version', { 
      ...logOptions, 
      metadata: { 
        error: error instanceof Error ? error.message : String(error),
        fallbackVersion: apiVersion.value 
      }
    });
  }
};

const toggleFeedback = () => {
  showFeedback.value = !showFeedback.value;
  if (intervalId) {
    clearInterval(intervalId);
  }
  const nextInterval = showFeedback.value ? 5000 : 10000;
  intervalId = setInterval(toggleFeedback, nextInterval);
};

onMounted(() => {
  fetchApiVersion();
  intervalId = setInterval(toggleFeedback, 10000);
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>